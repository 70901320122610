<template>
  <app-event event-id="partyon-mail.ru" :mapping="mapping"></app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";

export default {
  name: "AppPartyOnMailRuPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        quest: Quest,
      },
    };
  },
};
</script>